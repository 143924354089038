<template>
    <div
        class="central-units-list"
        id="central_unit_list"
        v-if="checkRightWithOr(['G_RESIDENCES', 'G_FICHES'])"
    >
        <div class="container-fluid">
            <div class="row">
                <div class="col"></div>
                <div class="col align-middle">
                    <h1>{{ $t("centralUnitsList.title") }}</h1>
                </div>
                <div class="col"></div>
            </div>
            <div class="row">
                <div class="col"></div>
                <div
                    class="col align-middle w-100 d-flex justify-content-center"
                >
                    <div class="search-container">
                        <div class="search-wrapper">
                            <BasicSearch
                                name="searchCentral"
                                :searchPlaceholder="
                                    $t('centralUnitsList.searchCentral')
                                "
                                @search="searchCentral"
                            />
                        </div>
                    </div>
                </div>
                <div class="col"></div>
            </div>
        </div>
        <div class="count">
            <h4>
                {{
                    $t("centralUnitsList.listing", {
                        count: centralUnitsCount,
                        first: indexFirst,
                        last: indexLast,
                    })
                }}
            </h4>
        </div>
        <div class="filters">
            <!-- TYPE FILTERS -->
            <div class="left-part">
                <div class="type-filters">
                    <tooltip
                        class="form-check form-check-inline"
                        :text="$t('centralUnitsList.realTime')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="real-time-filter"
                            value="rt"
                            v-model="modeFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="real-time-filter">
                            <img
                                :src="
                                    require('@/assets/images/products/real_time-small.png')
                                "
                                width="20"
                            />
                        </label>
                    </tooltip>
                    <tooltip
                        class="form-check form-check-inline"
                        :text="$t('centralUnitsList.readWrite')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="read-write-filter"
                            value="rw"
                            v-model="modeFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="read-write-filter">
                            <img
                                :src="
                                    require('@/assets/images/products/read_write.png')
                                "
                                width="25"
                            />
                        </label>
                    </tooltip>
                </div>
                <div class="update-status-filters">
                    <tooltip
                        class="form-check-inline"
                        :text="$t('global.status.ok')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="ok-filter"
                            value="ok"
                            v-model="statusFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="ok-filter">
                            <update-status-tag
                                :status="'ok'"
                                :showLabel="false"
                                from="central"
                            ></update-status-tag>
                        </label>
                    </tooltip>
                    <tooltip
                        class="form-check-inline"
                        :text="$t('global.status.inProgress')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="inprogress-filter"
                            value="inprogress"
                            v-model="statusFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="inprogress-filter">
                            <update-status-tag
                                :status="'inprogress'"
                                :showLabel="false"
                                from="central"
                            ></update-status-tag>
                        </label>
                    </tooltip>
                    <tooltip
                        class="form-check-inline"
                        :text="$t('global.status.needUpdate')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="needupdate-filter"
                            value="needupdate"
                            v-model="statusFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="needupdate-filter">
                            <update-status-tag
                                :status="'needupdate'"
                                :showLabel="false"
                                from="central"
                            ></update-status-tag>
                        </label>
                    </tooltip>
                    <tooltip
                        class="form-check-inline"
                        :text="$t('global.status.error')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="error-filter"
                            value="error"
                            v-model="statusFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="error-filter">
                            <update-status-tag
                                :status="'error'"
                                :showLabel="false"
                                from="central"
                            ></update-status-tag>
                        </label>
                    </tooltip>
                </div>
                <div class="connectit-filters">
                    <tooltip
                        class="form-check form-check-inline"
                        :text="$t('centralUnitsList.lora')"
                    >
                        <input
                            class="form-check-input"
                            type="checkbox"
                            id="connect-it-filter"
                            v-model="loraFilter"
                            @click="currentPage = 1"
                        />
                        <label class="form-check-label" for="connect-it-filter">
                            <img
                                :src="
                                    require('@/assets/images/products/connect_it.png')
                                "
                                width="100"
                            />
                        </label>
                    </tooltip>
                </div>
            </div>
            <div class="right-part">
                <basic-button
                    class="update-button"
                    @click="
                        goToV1View('data/majs/list/rid=' + this.idResidence)
                    "
                    color="blue"
                >
                    <i class="fas fa-sync-alt"></i>
                    <span class="mobile">
                        {{ $t("global.status.update") }}
                    </span>
                </basic-button>
                <basic-button
                    class="print-button"
                    @click="print()"
                    color="blue"
                >
                    <i class="fas fa-print"></i>
                    <span class="mobile">
                        {{ $t("entityDetails.actions.export") }}
                    </span>
                </basic-button>
            </div>
        </div>

        <table class="table central-units-table" v-if="!isLoading">
            <thead>
                <tr>
                    <th class="mobile"></th>
                    <th>
                        {{ $t("centralUnitsList.arrayColumns.name") }}
                    </th>
                    <th class="mobile">
                        {{ $t("centralUnitsList.arrayColumns.serial") }}
                    </th>
                    <th class="mobile">
                        {{ $t("centralUnitsList.arrayColumns.phone") }}
                    </th>
                    <th colspan="3">
                        {{ $t("centralUnitsList.arrayColumns.type") }}
                    </th>
                    <th class="mobile">
                        <div class="reduce_text">
                            <span>
                                {{
                                    $t(
                                        "centralUnitsList.arrayColumns.updateStatus"
                                    )
                                }}
                            </span>
                        </div>
                    </th>
                    <th class="mobile" width="200" />
                </tr>
            </thead>
            <tbody>
                <tr v-for="item in centralUnits" :key="item.id">
                    <td @click="goToCentralUnit(item.id)" class="mobile">
                        <i class="fas fa-server"></i>
                    </td>
                    <td @click="goToCentralUnit(item.id)">
                        {{ item.nom }}
                        <span class="only-mobile">
                            {{ item.serie }}
                        </span>
                    </td>
                    <td @click="goToCentralUnit(item.id)" class="mobile">
                        {{ item.serie }}
                    </td>
                    <td @click="goToCentralUnit(item.id)" class="mobile">
                        {{ item.tel }}
                    </td>
                    <td @click="goToCentralUnit(item.id)">
                        <img
                            class="mobile"
                            :src="
                                imageBaseURL +
                                'Images/Materiel/gsm-' +
                                item.gsm +
                                '.png'
                            "
                            width="18"
                            v-if="item.rw !== '1' && item.lite !== '1'"
                            :alt="`gsm-${item.gsm}`"
                        />
                    </td>
                    <td @click="goToCentralUnit(item.id)">
                        <img
                            class="mobile"
                            :src="
                                imageBaseURL +
                                $store.getters['images/getImageByType'](
                                    item.type
                                )
                            "
                            width="18"
                            :alt="
                                $store.getters['images/getImageByType'](
                                    item.type
                                )
                            "
                        />
                    </td>
                    <td @click="goToCentralUnit(item.id)">
                        <img
                            class="icon-rw"
                            v-if="item.rw === '1'"
                            :src="
                                require('@/assets/images/products/read_write.png')
                            "
                            width="18"
                            alt="read write"
                        />
                        <img
                            class="icon-rt"
                            v-else-if="item.rw !== '1' && item.lite !== '1'"
                            :src="
                                require('@/assets/images/products/real_time-small.png')
                            "
                            width="18"
                            alt="real time"
                        />
                        <img
                            class="icon-lora mobile"
                            v-if="item.lora === '1'"
                            :src="
                                require('@/assets/images/products/connect_it.png')
                            "
                            width="60"
                            alt="connect it"
                        />
                    </td>
                    <td @click="goToCentralUnit(item.id)">
                        <update-status-tag
                            :status="item.maj"
                            :rw="item.rw"
                            :lite="item.lite"
                            :mapp="item.MAPP"
                            :mes="item.CMES"
                            from="central"
                        />
                    </td>
                    <td class="mobile commissioning">
                        <span
                            v-if="item.rw === '1' && item.MAPP !== 'i_rwbpkg'"
                        >
                            <basic-button
                                class="commissioning-button"
                                color="blue"
                                @click="
                                    goToV1View('data/cen_majrw/id=' + item.id)
                                "
                            >
                                <!--
                                @click="goToV1View('data/majs/list/rid='+this.idResidence)" -->
                                <i class="fas fa-sync-alt"></i>
                                <span class="mobile">
                                    {{ $t("global.status.commissioning") }}
                                </span>
                            </basic-button>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="loader-bloc" v-if="isLoading">
            <loader class="list-loader"></loader>
        </div>

        <pagination
            v-show="pagesCount > 1"
            :pagesCount="pagesCount"
            :page="currentPage"
            v-on:new-current-page="currentPage = $event"
        >
        </pagination>
    </div>
</template>

<script>
import v1mixin from "@/mixins/v1.js"

import { getCentralUnitsByResidence } from "@/services/intratone/centralUnit.js"
import { printCentralUnitsByResidence } from "@/services/intratone/centralUnit.js"

import UpdateStatusTag from "@/components/UpdateStatusTag.vue"
import Loader from "@/components/basic/Loader.vue"
import BasicButton from "@/components/basic/BasicButton.vue"
import Pagination from "@/components/basic/Pagination.vue"
import Tooltip from "@/components/basic/Tooltip.vue"
import BasicSearch from "../basic/BasicSearch.vue"

export default {
    name: "CentralUnitsList",
    components: {
        UpdateStatusTag,
        Loader,
        Tooltip,
        Pagination,
        BasicButton,
        BasicSearch,
    },
    mixins: [v1mixin],
    props: {
        idResidence: {
            type: String,
            default: null,
        },
        idCage: {
            type: String,
            default: null,
        },
        lora: {
            type: Boolean,
            default: false,
        },
        activeTab: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            // List of central units
            centralUnits: [],
            // Filters
            modeFilter: [],
            // Search
            centralSearch: null,
            statusFilter: [],
            loraFilter: false,
            // Loading
            isLoading: false,
            // Pagination
            currentPage: 1,
            pagesCount: 1,
            centralUnitsCount: 0,
            limitPerPage: 10,
            // Locking
            locked: false,
            imageBaseURL: process.env.VUE_APP_IMAGES_URL,
        }
    },
    computed: {
        indexFirst() {
            return 1 + (this.currentPage - 1) * this.limitPerPage
        },
        indexLast() {
            return Math.min(
                this.limitPerPage + (this.currentPage - 1) * this.limitPerPage,
                this.centralUnitsCount
            )
        },
        // Parameters for central units query
        parameters() {
            let params = {
                limit: this.limitPerPage,
            }

            if (this.currentPage > 1) {
                params["page"] = this.currentPage
            }
            if (this.statusFilter.length > 0) {
                params["maj"] = this.statusFilter.join(",")
            }
            if (this.modeFilter.length === 1) {
                params["rw"] = +this.modeFilter.includes("rw")
            }
            if (this.loraFilter === true) {
                params["lora"] = +true
            }
            if (this.centralSearch !== null && this.centralSearch !== "") {
                params["q"] = this.centralSearch
            }
            return params
        },
        // Query from URL
        urlQuery() {
            return this.$route.query
        },
    },
    methods: {
        searchCentral(value) {
            this.centralSearch = value
        },
        goToCentralUnit(id) {
            if (
                this.checkRightWithOr([
                    "G_RESIDENCES",
                    "G_KEY",
                    "G_KEY_MOBIL",
                    "G_CLEPASS",
                    "G_FICHES",
                    "G_APP",
                    "G_APP_NOM",
                    "G_CODE_CLAV",
                    "G_CODE_TEL",
                    "G_AUTOS",
                    "G_HORAIRES",
                    "UP_VIGIKPROPASS",
                ])
            ) {
                this.$router.push({
                    name: "V1",
                    params: {
                        arg1: "data",
                        arg2: "centrale",
                        arg3: "id=" + id + "&redirect=1",
                    },
                })
            }
        },
        // Update URL query with filters values
        setQuery() {
            let params = {}
            if (this.currentPage > 1) {
                params["page"] = this.currentPage
            }
            if (this.modeFilter.length > 0) {
                params["mode"] = this.modeFilter.join(",")
            }
            if (this.statusFilter.length > 0) {
                params["status"] = this.statusFilter.join(",")
            }
            if (this.loraFilter === true) {
                params["connectit"] = +true
            }
            if (this.centralSearch !== null || this.centralSearch !== "") {
                params["q"] = this.centralSearch
            }
            this.$router.push({
                query: params,
            })
        },
        // Update filters values with URL query
        getQuery() {
            let query = this.$route.query
            if (query.page !== undefined) {
                this.currentPage = parseInt(query.page, 10)
            } else {
                this.currentPage = 1
            }
            if (query.mode !== undefined) {
                this.modeFilter = query.mode.split(",")
            } else {
                this.modeFilter = []
            }
            if (query.status !== undefined) {
                this.statusFilter = query.status.split(",")
            } else {
                this.statusFilter = []
            }
            if (query.connectit !== undefined) {
                this.loraFilter = query.connectit === 1
            } else {
                this.loraFilter = false
            }
        },
        async loadCentralUnits() {
            try {
                let params = {
                    ...{ idres: this.idResidence },
                    ...this.parameters,
                }
                // Start loading
                this.isLoading = true

                // Requesting central units
                let centralUnitsResponse = await getCentralUnitsByResidence(
                    params
                )
                if (centralUnitsResponse === null) {
                    throw new Error()
                }

                // If requested page does not exists : new request with last page
                let pages = parseInt(centralUnitsResponse.data._pages, 10)
                if (params.page && params.page > pages) {
                    this.currentPage = pages
                    this.setQuery()
                    params = {
                        ...{ idres: this.idResidence },
                        ...this.parameters,
                    }
                    centralUnitsResponse = await getCentralUnitsByResidence(
                        params
                    )
                }

                this.centralUnits = centralUnitsResponse.data.list
                this.pagesCount = pages > 0 ? pages : 1
                this.centralUnitsCount = parseInt(
                    centralUnitsResponse.data._count,
                    10
                )

                // Stop loading
                this.isLoading = false
            } catch (error) {
                console.log(error)
            }
        },
        print() {
            let params = {
                idres: this.idResidence,
                ...this.printParameters(),
            }
            printCentralUnitsByResidence(params)
                .then((response) => {
                    return response
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        printParameters() {
            let params = {}

            if (this.currentPage > 1) {
                params["page"] = this.currentPage
            }
            //En fonction de la taille
            if (this.modeFilter.length === 1) {
                let rw = 0
                switch (this.modeFilter[0]) {
                    case "rw":
                        rw = 1
                        break
                }
                //Ton paramètre à modifier
                params["rw"] = rw
            }
            if (this.statusFilter.length > 0) {
                params["MAJ"] = this.statusFilter.join(",")
            }
            if (this.loraFilter === true) {
                params["lora"] = +true
            }
            if (this.idCage !== null) {
                params["idcage"] = this.idCage
            }

            return params
        },
    },
    watch: {
        async parameters() {
            // If locked : do nothing
            if (this.locked === false) {
                // Locking in order to prevent loop between setQuery and getQuery
                this.locked = true

                this.setQuery()
                await this.loadCentralUnits()

                // Unlocking
                this.locked = false
            }
        },
        async idResidence() {
            // If locked : do nothing
            if (
                this.locked === false &&
                this.$route.params.tab === "central-units"
            ) {
                // Locking in order to prevent loop between setQuery and getQuery
                this.locked = true

                this.getQuery()
                await this.loadCentralUnits()

                // Unlocking
                this.locked = false
            }
        },
        async urlQuery() {
            // If locked : do nothing
            if (
                this.locked === false &&
                this.$route.params.tab === "central-units"
            ) {
                this.getQuery()
            }
        },
    },
    created() {
        if (
            this.idResidence !== null &&
            this.$route.params.tab === "central-units"
        ) {
            this.getQuery()
            this.loadCentralUnits()
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

@media all and (min-width: 768px) {
    .only-mobile {
        display: none;
    }
}

.reduce_text {
    @media all and (max-width: 768px) {
        width: 50px;
    }

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.central-units-list {
    padding: 30px 0 30px 0;
    border-radius: 30px;
    box-shadow: 0px 3px 30px 0 rgb(0 0 0 / 15%);

    .button-print {
        display: flex;
        width: min-content;
        justify-content: end;
        color: white;
        padding: 1em 0.5em;
        .print-wrapper {
            display: flex;
            align-items: center;
            gap: 0.5em;
            height: 100%;
            cursor: pointer;

            h1 {
                margin: 0;
            }

            margin: 0 2em;
        }
    }

    .search-container {
        display: flex;
        justify-content: center;
        padding-bottom: 2em;
        width: 100%;

        .search-wrapper {
            position: relative;
            width: 100%;
            height: 35px;
            align-items: center;

            button {
                position: absolute;
                height: 100%;
                border-radius: 10px;
                background-color: $blue-neutral;
                border: none;
                cursor: pointer;

                i {
                    color: white;
                    padding: 0 5px;
                    font-size: $big;
                }
            }

            input {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                border: 1px solid $blue-neutral;
                padding-left: 3.5em;
                padding-right: 2em;

                &:focus {
                    border: 1px solid $blue-neutral;
                }
            }

            .cancel-button {
                color: $blue-neutral;
                position: absolute;
                cursor: pointer;
                right: 0;
                padding-right: 0.5em;
            }
        }
    }

    h1 {
        font-size: $big;
        font-family: "Avenir Heavy";
        text-align: center;
        margin-bottom: 30px;
    }

    .count {
        padding: 1rem 2rem;

        @media all and (max-width: 768px) {
            padding: 1rem 0.8rem;
        }

        h4 {
            font-size: $normal;
        }
    }

    .filters {
        display: flex;
        gap: 10px;
        padding: 0 2rem;
        padding-bottom: 1rem;

        @media all and (min-width: 768px) and (max-width: 1380px) {
            flex-direction: column;
        }

        @media all and (max-width: 768px) {
            flex-direction: column;
            padding: 0 0.8rem;
            padding-bottom: 1rem;
        }

        @media all and (min-width: 1380px) {
            justify-content: space-between;
        }

        .left-part {
            width: 100%;

            display: flex;
            flex-wrap: wrap;
            gap: 1em;
            .type-filters {
                display: flex;
                height: 55px;
                justify-content: center;
                align-content: center;
                align-items: center;
                background-color: $grey-lighter;
                border-radius: 15px;
                gap: 1em;
                padding: 0 1em;

                .form-check {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .form-check-inline {
                    padding: 0;
                    margin-right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 0.5em;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .update-status-filters {
                display: flex;
                height: 55px;
                justify-content: center;
                align-content: center;
                align-items: center;
                background-color: $grey-lighter;
                border-radius: 15px;
                gap: 1em;
                padding: 0 1em;

                .form-check {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .form-check-inline {
                    padding: 0;
                    margin-right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 0.5em;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .form-check-input {
                    cursor: pointer;
                }

                .form-check-label {
                    cursor: pointer;
                }
            }

            .connectit-filters {
                display: flex;
                height: 55px;
                justify-content: center;
                align-content: center;
                align-items: center;
                background-color: $grey-lighter;
                border-radius: 15px;
                gap: 1em;
                padding: 0 1em;
                .form-check {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .form-check-inline {
                    padding: 0;
                    margin-right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    gap: 0.5em;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .form-check {
                .form-check-input {
                    cursor: pointer;
                    margin: 0 !important;
                }

                .form-check-label {
                    cursor: pointer;
                }
            }
        }

        .right-part {
            display: flex;
            gap: 1em;

            .update-button,
            .print-button {
                display: flex;
                gap: 0.5em;
                align-items: center;
                justify-content: center;

                @media all and (min-width: 1024px) {
                    min-width: 9rem;
                }

                @media all and (min-width: 768px) and (max-width: 1024px) {
                    min-width: 9rem;
                }

                @media all and (max-width: 768px) {
                    width: 4em;
                }
            }
        }
    }

    .loader-bloc {
        margin: 40px auto 0 auto;
        width: fit-content;
    }

    table {
        border-color: $grey-neutral;
        color: $blue-dark;

        thead {
            border: none;

            tr {
                border-color: $grey-neutral;

                th {
                    font-size: $normal;
                }
            }
        }

        tbody {
            border-color: $grey-neutral;

            tr {
                border-color: $grey-neutral;
                transition: background-color 100ms, color 100ms;

                td {
                    vertical-align: middle;
                    padding: 10px;
                    font-size: $normal;
                }

                td:first-child {
                    text-align: center;
                }

                td:last-child {
                }

                .icon-lora {
                    margin-left: 10px;
                }
            }

            tr:hover {
                background-color: $grey-light;
                cursor: pointer;
                color: $orange-neutral;

                td {
                }
            }
        }
    }
}

.commissioning {
    justify-content: center;
    align-content: center;
    align-items: center;
}

.commissioning-button {
    padding: 20px;

    i {
        margin-right: 10px;
    }
}
</style>
